<template>
  <section class="section section-standalone" id="sapa-warga">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between pl-2">
          <h1 class="title-font text-size-title text-uppercase mb-0">
            <img src="@/assets/img/icon/hi.png" /> SapaWarga
          </h1>
          <div class="c-dashboard-title-option d-flex align-items-center">
            <!-- <select class="form-control form-control-sm mr-2 form-control-custom">
                        <option>Minggu Ini</option>
                    </select> -->
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
              v-on:change="kategoriChange()"
              v-model="kategori"
            >
              <option value="">Kategori</option>
              <option
                :value="item.id"
                v-for="item in listKategori"
                :key="item.id"
              >
                {{ item.nama_kategori }}</option
              >
            </select>
            <!-- <select class="form-control form-control-sm form-control-custom" style="width: 160px">
                        <option>Kota/Kabupaten</option>
                    </select> -->
          </div>
        </div>
        <div class="grid-section">
          <div class="grid-section-item">
            <div class="bg-white rounded border border-radius-style">
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4 class="mb-0">
                  Jumlah Usulan
                </h4>
              </div>
              <div class="card-dashboard-content">
                <Map :laporan="laporan" v-if="maps" />
                <!-- <img src="@/assets/img/maps.png" alt="" class="img-responsive"
                                 style="height: 350px;object-fit: cover;"> -->
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div class="bg-white rounded border border-radius-style">
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4 class="mb-0">
                  Leaderboard Aktifitas User
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <ul class="p-0 list-style-type-none c-list">
                  <li
                    v-for="(item, key) in leaderboard"
                    :key="key"
                    :class="
                      `list-with-progress d-flex align-items-center w-100 rounded py-2 px-3 ${
                        key % 2 == 0 ? 'bg-orange-new' : 'bg-white border'
                      } `
                    "
                  >
                    <span
                      class="list-with-progress-number mr-2 text-orange-new"
                    >
                      {{ ++key }}
                    </span>
                    <img src="@/assets/img/icon/icon-sapa-warga-2.svg" alt="" />
                    <div class="list-with-progress-content w-100 ml-2">
                      <div
                        class="list-with-progress-content-first d-flex align-items-center justify-content-between"
                      >
                        <p class="mb-0 text-size-small">
                          {{ item.nama }}
                        </p>
                        <span class="text-orange-new">
                          {{ item.score }}
                        </span>
                      </div>
                      <div class="list-with-progress-content-second">
                        <div class="progress" style="height: 4px;">
                          <div
                            style="background-color: #FF991C;"
                            class="progress-bar rounded w-100"
                            role="progressbar"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  class="loadmore d-flex justify-content-center"
                  style="margin-top:-10px"
                >
                  <button
                    class="btn btn-loadmore"
                    v-if="
                      leaderboard.length > 0 &&
                        limit_leaderboard < listleaderboard.length
                    "
                    v-on:click="limitleaderboardChange()"
                  >
                    LOAD MORE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div class="bg-white rounded border border-radius-style">
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4 class="mb-0">
                  Berita yang Paling Banyak Dilihat
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <ul class="p-0 list-style-type-none c-list">
                  <li
                    v-for="(item, key) in new_most_like"
                    :key="key"
                    :class="
                      `d-flex px-3 c-new-list py-1 rounded align-items-center justify-content-between ${
                        key % 2 == 0
                          ? 'bg-blue-light-new border-0'
                          : 'bg-white border '
                      } `
                    "
                  >
                    <div class="c-new-list-title d-flex align-items-center">
                      <span
                        class="primary-font c-new-list-number rounded-circle d-flex align-items-center justify-content-center mr-3"
                      >
                        {{ ++key }}
                      </span>
                      <div class="crop">
                        <img
                          :src="item.cover"
                          alt=""
                          class=""
                          v-on:click="GambarShow(item.cover, item.judul)"
                          style="cursor:pointer"
                        />
                      </div>

                      <p class="mb-0 primary-font text-size-small">
                        {{ item.judul }}
                      </p>
                    </div>
                    <div class=" c-new-list-status d-flex flex-column">
                      <span
                        class="primary-font mb-0 text-center c-new-list-status-number"
                      >
                        {{ item.total }}
                      </span>
                      <span
                        class="primary-font text-center text-size-super-mini"
                      >
                        dilihat
                      </span>
                    </div>
                  </li>
                </ul>
                <div class="loadmore mt-3 d-flex justify-content-center">
                  <button
                    class="btn btn-loadmore"
                    v-if="
                      new_most_like.length > 0 &&
                        limit_new_most_like < listnew_most_like.length
                    "
                    v-on:click="limitnew_most_likeChange()"
                  >
                    LOAD MORE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div class="bg-white rounded border h-100 border-radius-style">
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4 class="mb-0">
                  Top Polling (Vote)
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <ul class="p-0 list-style-type-none c-list">
                  <li
                    v-for="(item, key) in polling"
                    :key="key"
                    v-on:click="modalPolling(item.pertanyaan, item.jawaban)"
                  >
                    <a
                      href="#modalSapaWarga"
                      data-taget="#modalSapaWarga"
                      data-toggle="modal"
                      :class="
                        `d-flex border-0 px-3 c-new-list py-1 rounded align-items-center justify-content-between text-black   ${
                          key % 2 == 0
                            ? 'bg-green-new border-0'
                            : 'bg-white border '
                        }`
                      "
                    >
                      <div class="c-new-list-title d-flex align-items-center">
                        <span
                          class="primary-font text-green-new c-new-list-number rounded-circle d-flex
                                            align-items-center justify-content-center mr-3"
                        >
                          {{ ++key }}
                        </span>
                        <img
                          src="@/assets/img/icon/icon-sapa-warga-3.svg"
                          alt=""
                        />
                        <p class="mb-0 primary-font text-size-small">
                          {{ item.pertanyaan }}
                        </p>
                      </div>
                      <div class=" c-new-list-status d-flex flex-column">
                        <span
                          class="primary-font mb-0 text-center c-new-list-status-number text-green-new"
                        >
                          {{ item.votes }}
                        </span>
                        <span
                          class="primary-font text-center text-size-super-mini"
                        >
                          vote
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="loadmore mt-3 d-flex justify-content-center">
                  <button
                    class="btn btn-loadmore"
                    v-if="
                      polling.length > 0 && limit_polling < listpolling.length
                    "
                    v-on:click="limitpollingChange()"
                  >
                    LOAD MORE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3" style="margin-right: -28px;">
        <div class="col-md-12">
          <div class="bg-white rounded border border-radius-style">
            <div class="card-dashboard-title border-bottom px-3 py-2">
              <h4 class="mb-0">
                Kegiatan RW
              </h4>
            </div>
            <div class="card-dashboard-content p-3">
              <ul class="p-0 list-style-type-none c-list">
                <li
                  v-for="(item, key) in kegiatan_rw"
                  :key="key"
                  :class="
                    `d-flex px-3 c-new-list py-1 rounded align-items-center justify-content-between ${
                      key % 2 == 0
                        ? 'bg-blue-light-new border-0'
                        : 'bg-white border '
                    } `
                  "
                >
                  <div class="c-new-list-title d-flex align-items-center">
                    <span
                      class="primary-font c-new-list-number rounded-circle d-flex align-items-center justify-content-center mr-3"
                    >
                      {{ ++key }}
                    </span>
                    <div class="crop1">
                      <img
                        :src="item.image_path_full"
                        alt=""
                        class=""
                        v-on:click="GambarShow(item.image_path_full, item.text)"
                        style="cursor:pointer"
                      />
                    </div>

                    <p class="mb-0 primary-font text-size-small">
                      Nama : <b>{{ item.user.name }}</b> <br />
                      Alamat :
                      <b
                        >RW. {{ item.user.rw }} {{ item.user.kelurahan }}
                        {{ item.user.kecamatan }} {{ item.user.kabkota }}</b
                      >
                      <br /><br />
                      Kegiatan : {{ item.text }}
                    </p>
                  </div>
                  <div class=" c-new-list-status d-flex flex-column">
                    <span
                      class="primary-font mb-0 text-center c-new-list-status-number"
                    >
                      {{ item.likes_count }}
                    </span>
                    <span class="primary-font text-center text-size-super-mini">
                      disukai
                    </span>
                  </div>
                </li>
              </ul>
              <div class="loadmore mt-3 d-flex justify-content-center">
                <button
                  class="btn btn-loadmore"
                  v-if="
                    kegiatan_rw.length > 0 &&
                      limit_kegiatan_rw < listkegiatan_rw.length
                  "
                  v-on:click="limitkegiatan_rwChange()"
                >
                  LOAD MORE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row sumber-data mt-3"
        v-if="sumber_data != null"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br> 
                              Kontak : ${
                                sumber_data.kontak == null
                                  ? '-'
                                  : sumber_data.kontak
                              }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
    </div>
    <LightBox
      :images="itemImage"
      v-if="status_gambar"
      :show-caption="true"
      :show-thumbs="false"
    ></LightBox>
    <div
      class="modal fade"
      id="modalSapaWarga"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalSapaWargaTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-custom border-0 rounded-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="close rounded-circle bg-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times fa-xs text-body" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="title-w-line--right p-3">
              <h4
                class="mb-0 text-capitalize text-size-large titilium-font font-weight-normal"
              >
                Top Polling
              </h4>
            </div>
            <p
              class="primary-font text-center"
              v-if="pollingPopUp.pertanyaan != ''"
            >
              {{ pollingPopUp.pertanyaan }}
            </p>
            <div class="row pb-3 justify-content-center">
              <div class="col-md-6">
                <div class="chart-doughnut--wrapper">
                  <canvas
                    id="sapa-warga-modal"
                    width="200"
                    height="200"
                    :chart="chartPolling"
                  ></canvas>
                  <div class="chart-doughnut--text">
                    <h2 class="mb-1 text-size-semi-large secondary-font">
                      {{ totalPolling }}
                    </h2>
                    <h6
                      class="mb-0 text-uppercase text-size-small font-weight-bold secondary-font"
                    >
                      Tanggapan
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div
                class="col-md-5"
                v-for="(item, key) in pollingPopUp.data"
                :key="key"
              >
                <div
                  class="card-dashboard card-dashboard--blue bg-white rounded p-3 mb-3 border"
                  :style="`border-left-color:${color[key]} !important`"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--warning">
                      <span
                        class="icon-blue-new rounded-circle border p-1 c-list-indicator c-list-indicator--lg"
                        :style="
                          `background:${color[key]} !important;border-color:${color[key]} !important;`
                        "
                      >
                        <i class="fas fa-check fa-lg"></i>
                      </span>
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="text-blue-new text-size-large mb-1 d-flex align-items-center justify-content-end"
                        :style="`color:${color[key]} !important`"
                      >
                        {{ item.votes }}
                        <span class="text-size-small ml-1">
                          ({{
                            parseInt(
                              ((item.votes / totalPolling) * 100).toFixed(2)
                            ) || 0
                          }}%)
                        </span>
                      </h4>
                      <p
                        class="mb-0 text-size-mini text-uppercase font-weight-normal"
                      >
                        {{ item.answer_body }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Map from '@/components/MapSapaWarga.vue';
import LightBox from 'vue-image-lightbox';
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
export default {
  metaInfo() {
    return {
      title: 'SapaWarga'
    };
  },
  components: {
    Map,
    LightBox
  },
  data() {
    return {
      itemImage: [],
      status_gambar: false,
      maps: false,
      sumberTooltip: false,
      sumber_data: null,
      chartPolling: {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: [200, 58],
              backgroundColor: [
                '#FFC650',
                '#FD8F30',
                '#F4776E',
                '#01A7E5',
                '#17C12B',
                '#9BC065',
                '#98908C',
                '#01658a',
                '#FDC730',
                '#EB5352'
              ],
              borderWidth: 0
            }
          ]
        },
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          maintainAspectRatio: true
        }
      },
      laporan: [],
      listnew_most_like: [],
      listpolling: [],
      listleaderboard: [],
      pollingPopUp: {
        data: [],
        pertanyaan: ''
      },
      listkegiatan_rw: [],
      totalPolling: 0,
      kategori: '',
      listKategori: [],
      limit_new_most_like: 5,
      limit_kegiatan_rw: 5,
      limit_polling: 10,
      limit_leaderboard: 5,
      color: [
        '#FFC650',
        '#FD8F30',
        '#F4776E',
        '#01A7E5',
        '#17C12B',
        '#9BC065',
        '#98908C',
        '#01658a',
        '#FDC730',
        '#EB5352'
      ]
    };
  },
  mounted() {
    setTimeout(() => {
      this.maps = true;
    }, 800);
    this.loadData();
  },

  methods: {
    GambarShow(item, text) {
      this.status_gambar = false;
      setTimeout(() => {
        this.status_gambar = true;
      }, 200);
      this.itemImage = [
        {
          src: item,
          // thumb:item,
          caption: text
        }
      ];
    },
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    },
    loadData() {
      window.axios.post(window.base_api + 'page/sapaWarga').then(response => {
        if (response.status == 200) {
          this.sumber_data = response.data.sumber_data;
          this.laporan = response.data.laporan;
          this.listnew_most_like = response.data.new;
          this.listkegiatan_rw = response.data.kegiatan_rw;
          this.listpolling = response.data.polling;
          this.listleaderboard = response.data.leaderboard;
          if (this.listnew_most_like.length > 0) {
            this.listnew_most_like.sort(this.compareNew);
          }
          if (this.listpolling.length > 0) {
            this.listpolling.sort(this.comparePolling);
          }
          if (this.listleaderboard.length > 0) {
            this.listleaderboard.sort(this.compareLeaderboard);
          }
          if (this.listkegiatan_rw.length > 0) {
            this.listkegiatan_rw.sort(this.comparekegiatan_rw);
          }

          this.listKategori = response.data.kategori;
        }
      });
    },
    modalPolling(pertanyaan, jawaban) {
      this.pollingPopUp.data = jawaban;

      this.pollingPopUp.pertanyaan = pertanyaan;
      var total = jawaban.map(d => {
        return parseInt(d.votes);
      });
      this.chartPolling.data.datasets[0].data = total;

      this.totalPolling = this.sumArray(total);

      this.setChart();
    },
    sumArray(array) {
      return array.reduce((a, b) => a + b, 0);
    },
    setChart() {
      let ctx = document.getElementById('sapa-warga-modal').getContext('2d');

      new window.Chart(ctx, {
        type: this.chartPolling.type,
        data: this.chartPolling.data,
        options: this.chartPolling.options
      });
    },
    kategoriChange() {
      this.limit_polling = 10;
      if (this.kategori != '') {
        window.axios
          .post(window.base_api + 'page/sapaWarga', {
            kategori: this.kategori
          })
          .then(response => {
            if (response.status == 200) {
              this.listpolling = response.data.polling;
            }
          });
      } else {
        window.axios.post(window.base_api + 'page/sapaWarga').then(response => {
          if (response.status == 200) {
            this.listpolling = response.data.polling;
          }
        });
      }
    },
    limitnew_most_likeChange() {
      this.limit_new_most_like += 5;
    },
    limitkegiatan_rwChange() {
      this.limit_kegiatan_rw += 5;
    },

    limitpollingChange() {
      this.limit_polling += 5;
    },
    limitleaderboardChange() {
      this.limit_leaderboard += 5;
    },
    compareNew(a, b) {
      a = a.total;
      b = b.total;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    },
    comparePolling(a, b) {
      a = a.tanggal;
      b = b.tanggal;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    },
    compareLeaderboard(a, b) {
      a = a.score;
      b = b.score;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    },
    comparekegiatan_rw(a, b) {
      a = a.likes_count;
      b = b.likes_count;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    }
  },
  computed: {
    new_most_like() {
      return this.listnew_most_like.slice(0, this.limit_new_most_like);
    },
    polling() {
      return this.listpolling.slice(0, this.limit_polling);
    },
    leaderboard() {
      return this.listleaderboard.slice(0, this.limit_leaderboard);
    },
    kegiatan_rw() {
      return this.listkegiatan_rw.slice(0, this.limit_kegiatan_rw);
    }
  }
};
</script>
<style>
.vue-lb-info {
  height: auto;
}
</style>
<style scoped>
.crop {
  /* width: 30%; */
  height: 30%;
  /* overflow: hidden; */
}

.crop img {
  /* height: 100%;
        width: 100%; */
  /* background-position: center; */
  /* Make the background image cover the area of the <div>, and clip the excess */
  /* background-size: cover; */
  border-radius: 4px !important;
  width: 150px;
  height: 100px;
  margin: 2px;
}

.crop1 img {
  /* height: 100%;
        width: 100%; */
  /* background-position: center; */
  /* Make the background image cover the area of the <div>, and clip the excess */
  /* background-size: cover; */
  border-radius: 4px !important;
  width: 150px;
  height: 100px;
  margin: 2px;
}
</style>
