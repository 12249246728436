<template>
  <mapbox
    :access-token="accessToken"
    @map-load="onMapLoaded"
    :map-options="{
      style: mapStyle,
      center: center,
      zoom: zoom
    }"
    :geolocate-control="{
      show: true,
      position: 'top-left'
    }"
    :fullscreen-control="{
      show: true,
      position: 'top-left'
    }"
  />
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
import mapboxgl from 'mapbox-gl';

export default {
  props: {
    laporan: Array
  },
  components: { Mapbox },
  data() {
    return {
      mapbox: null,
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      center: [107.668887, -7.090911],
      zoom: 7,
      accessToken:
        'pk.eyJ1IjoidmNvbmZpY2tlciIsImEiOiJjazJ5ZGtyMGIwNnliM2NvOGVvZnhoOGMwIn0.-5kuq-J8FTqiYeNAv_qwRA',
      geojson: {}
    };
  },
  mounted() {},

  methods: {
    onMapLoaded(map) {
      // _map = map;
      for (var icon_name in window.map_icons) {
        let a = icon_name;
        map.loadImage(window.map_icons[a], function(error, image) {
          map.addImage(a, image);
        });
      }
      var koordinat = this.laporan.map(item => {
        return [parseFloat(item.longitude), parseFloat(item.latitude), 0];
      });
      var feature = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
          }
        },
        features: []
      };
      for (const key in koordinat) {
        if (Object.prototype.hasOwnProperty.call(koordinat, key)) {
          // if (koordinat.hasOwnProperty(key)) {
          feature.features.push({
            type: 'Feature',
            properties: {
              description: `<p><strong>${this.laporan[key].kota}</strong></p>
                                        <p><strong>Jumlah Usulan : ${this.laporan[key].total}</strong></p>`
            },
            geometry: {
              type: 'Point',
              coordinates: koordinat[key]
            }
          });
        }
      }

      this.geojson = feature;
      this.loadMap(map);
    },
    loadMap(map) {
      setTimeout(() => {
        map.addSource('attractionsSapa', {
          type: 'geojson',
          data: this.geojson,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50
        });
        map.addLayer({
          id: 'clustersSapa',
          type: 'symbol',
          source: 'attractionsSapa',
          filter: ['has', 'point_count'],
          layout: {
            'icon-image': [
              'step',
              ['get', 'point_count'],
              'marker_radius_small',
              100,
              'marker_radius_medium',
              750,
              'marker_radius_large'
            ],
            'icon-size': 1,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true
          }
        });

        map.addLayer({
          id: 'cluster-countSapa',
          type: 'symbol',
          source: 'attractionsSapa',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Roboto Bold'],
            'text-size': 14,
            'text-offset': [0, 0.1]
          },
          paint: {
            'text-color': '#000'
          }
        });

        map.addLayer({
          id: 'placesSapa',
          type: 'symbol',
          source: 'attractionsSapa',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': 'cat',
            'icon-size': 0.12
          }
        });
        map.on('click', 'clustersSapa', function(e) {
          var features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
          });
          var clusterId = features[0].properties.cluster_id;
          map
            .getSource('attractionsSapa')
            .getClusterExpansionZoom(clusterId, function(err, zoom) {
              if (err) return;

              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              });
            });
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'placesSapa', function() {
          map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'placesSapa', function() {
          map.getCanvas().style.cursor = '';
        });
        map.on('click', 'placesSapa', function(e) {
          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.description;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map);
        });
      }, 1000);
    }
  }
};
</script>

<style>
#map {
  height: 350px;
}
.mapboxgl-popup-close-button {
  display: none;
}
</style>
