var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mapbox',{attrs:{"access-token":_vm.accessToken,"map-options":{
    style: _vm.mapStyle,
    center: _vm.center,
    zoom: _vm.zoom
  },"geolocate-control":{
    show: true,
    position: 'top-left'
  },"fullscreen-control":{
    show: true,
    position: 'top-left'
  }},on:{"map-load":_vm.onMapLoaded}})}
var staticRenderFns = []

export { render, staticRenderFns }